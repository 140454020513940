<template>
  <div>
    <div>      
        <b-card title="">
          <div class="mb-1">
            <router-link
              :to="`/events/list`"
              class="link-none"
            >
              <feather-icon icon="ArrowLeftIcon" size="20" class="" />
            </router-link>
          </div>
        <p>Lives:</p>
        <b-form action="">          
          <div class="d-flex justify-content-end mb-2">
            <b-form-input
              v-model="searchValue"
              class="d-inline-block mr-1"
              placeholder="Filtrar..."
              @keyup="filtrarLives"
            />
          </div>
        </b-form>
        <div class="grid">
          <div class="grid-item img-event d-flex flex-column justify-content-end" style="max-width: 450px; height: 250px;" v-for="live in lives" :key="live.id">
            <b-link @click="tryToGetIn(live.id)">
              <img
                style=" object-fit: cover;  height: 200px;"
                class="rounded w-100  border border-primary "
                :src="BaseUrl + '/storage/images/event_live_banner/' + live.logo"
                :alt="live.title"
              />
              <h3>{{ live.title }}</h3>
            </b-link>
          </div>
        </div>
      </b-card>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  components: {
  },
  setup() {
  },
  data() {
    return {
      lives: [],
      BaseUrl: process.env.VUE_APP_API_BASE,
      searchValue: null,
    };
  },
  computed: {
    sessionInfo() {
      return this.$store.state.auth.sessionInfo
    },
  },
  beforeMount() {
    this.fetchLives();
  },
  created() {
    
  },
  watch: {
  },
  methods: {
    filtrarLives() {
      if (this.searchValue && this.searchValue.trim() !== '') {
        const termoPesquisa = this.searchValue.trim().toLowerCase();
        this.lives = this.lives.filter(live => live.title.toLowerCase().includes(termoPesquisa));
      } else {
        this.fetchLives();
      }
    },
    fetchLives() {
      this.frameLive = false;
      const apiUrl = process.env.VUE_APP_API + "/events/lives/get-lives/" + this.$route.params.id;
      axios
        .get(apiUrl)
        .then((response) => {
          this.lives = response.data.lives;
        })
        .catch((error) => {
          console.error("Error fetching lives:", error);
        });
    },
    tryToGetIn(live_id) {

      const axiosConfig = {
        headers: {
          'Authorization': 'Bearer ' + this.token,
        }
      };
      
      const apiUrl = process.env.VUE_APP_API + `/events/lives/get-lives/${live_id}`;

      let status_permission = null

      axios
      .get(apiUrl, axiosConfig)
      .then((response) => {
        this.lives = response.data.response;
        
        status_permission = response.data.status
        console.log('status: '+status_permission)
        
        if (status_permission === 200) {
          this.$router.push({ name: 'live_show', params: { id: live_id, id_old: this.$route.params.id }})
        }
      })
      this.frameLive = true;
    },
  },
};
</script>

<style scoped>
.img-event {
  border-color: #161D31 !important;
  border-width: 2px !important;
  margin-bottom: 10px;
}

.link-none {
  text-decoration: none !important;
  color: inherit;
}
.grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
}

.bg-dark-blue {
  background-color: #161d31 !important;
}

@media (max-width: 768px) {
  .grid {
    grid-template-columns: 1fr;
  }
}
</style>